import React, { useCallback, useRef, useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { updateBalance } from "../../slices/auth";
import { Navigate, Link } from 'react-router-dom';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "./TradingGraph.css";
import { faCircleQuestion, faChartLine, faDownLong } from "@fortawesome/free-solid-svg-icons";
import Valuechanger from "./Valuechanger";
import axios from "axios";
import Highcharts from 'highcharts/highstock';
import HighchartsReact from 'highcharts-react-official';
import { get_user_single_data } from "../../common/Api";

// Import additional required modules for Stock Tools
import StockTools from 'highcharts/modules/stock-tools';
import AnnotationsAdvanced from 'highcharts/modules/annotations-advanced';
import DragPanes from 'highcharts/modules/drag-panes';
import Indicators from 'highcharts/indicators/indicators-all';
import FullScreen from 'highcharts/modules/full-screen';
import toast, { Toaster } from 'react-hot-toast';
import NonExpiredBetsTable from './NonExpiredBetsTable'; // Adjust the path as needed


// Initialize the modules
StockTools(Highcharts);
AnnotationsAdvanced(Highcharts);
DragPanes(Highcharts);
Indicators(Highcharts);
FullScreen(Highcharts);

const TradingGraph = () => {

  const [currentValue, setCurrentValue] = useState(null);
  const apiUrl = process.env.REACT_APP_API_URL;
  const [isActive, setisActive] = useState(false);
  const [timeMinusThirty, setTimeMinusThirty] = useState(null);
  const chartRef = useRef(null); // Store the chart instance

  const addClass = () => {
    setisActive(!isActive);
  };
  const removeClass = () => {
    setisActive(false);
  };

  const { user: currentUser, balanceAmount, activeCoinId } = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const [currentCoin, setCurrentCoin] = useState("6708ef77652a6be8b13b34a1");
  const [bitcoinPrice, setBitcoinPrice] = useState(null);
  const [thresholds, setThresholds] = useState([]);
  const [balance, setBalance] = useState(null);
  const [error, setError] = useState(null);
  const [activeSession, setActiveSession] = useState(0);
  const token = JSON.parse(localStorage.getItem("token"))

  const [coinName, setCoinName] = useState('Bitcoin');
  const [bets, setBets] = useState([]);
  const [expiredBets, setExpiredBets] = useState([]);


  const getSingleUserData = async () => {
    const data = await get_user_single_data(currentUser._id, token)
    setBalance(data?.data?.activeSession === 0 ? data?.data?.practiceBalance : data?.data?.balance);
    //dispatch(updateBalance(data?.data?.activeSession === 0 ? data?.data?.practiceBalance : data?.data?.balance));
    setActiveSession(data?.data?.activeSession);
  }

  console.log('activeCoinUI td =>', activeCoinId);

  const fetchNonExpiredBets = async () => {
    try {
      const response = await axios.get(`${apiUrl}/price/fetch_non_expired_bets`, {
        params: { userId: currentUser._id }
      });

      if (response.data.status) {
        const newBets = response.data.data.allBets;

        // Get current date and time
        const now = new Date();

        // Filter out non-expired bets and expired bets
        const nonExpiredBets = newBets.filter(bet => new Date(bet.expiration_time) > now);
        const expiredBetsList = newBets.filter(bet => new Date(bet.expiration_time) <= now);

        // Create a set of existing bet IDs for quick lookup
        const existingBetIds = new Set(bets.map(bet => bet._id));

        // Filter out any new bets that are already in the existing bets
        const uniqueNonExpiredBets = nonExpiredBets.filter(bet => !existingBetIds.has(bet._id));

        // Update the bets state with unique non-expired bets
        setBets(prevBets => [...uniqueNonExpiredBets]);

        // Update expired bets (avoid duplicates)
        setExpiredBets(prevExpired => {
          const newExpiredBets = expiredBetsList.filter(expiredBet => !prevExpired.some(prev => prev._id === expiredBet._id));
          return [...prevExpired, ...newExpiredBets];
        });
      }
    } catch (error) {
      console.error('Error fetching bets:', error);
    }
  };

  const chartOptions = {
    chart: {
      renderTo: 'container', // Render to the container
      animation: {
        duration: 800, // Increase animation duration for smoothness
        easing: 'easeOutExpo', // Smooth easing function
      },
    },
    title: {
      text: `${coinName} Price`,
    },
    xAxis: {
      timezone: 'Asia/Kolkata',
      minRange: 2000,
      crosshair: {
        className: 'highcharts-crosshair-custom',
        enabled: true,
      },
      labels: {
        format: '{value:%H:%M:%S}', // Format labels as YYYY-MM-DD HH:MM:SS
      },
      type: 'datetime'
    },
    yAxis: [
      {
        title: {
          text: 'price (USD)',
        },
        plotLines: [],
        crosshair: {
          snap: false,
          className: 'highcharts-crosshair-custom',
          label: {
            className: 'highcharts-crosshair-custom-label',
            enabled: true,
            format: '{value:.2f}',
          },
          zIndex: 5,
          enabled: true,
        },
        labels: {
          align: 'left',
        },
        height: '70%',
      },
      {
        title: {
          text: 'volume',
        },
        crosshair: {
          className: 'highcharts-crosshair-custom',
          snap: false,
          enabled: true,
          label: {
            format:
              '{#if value ge 1000000} {(divide value 1000000):.2f} ' +
              'M {else} {value} {/if}',
            className: 'highcharts-crosshair-custom-label',
            enabled: true,
          },
        },
        labels: {
          align: 'left',
        },
        top: '70%',
        height: '30%',
        offset: 0,
      },
    ],
    series: [
      {
        pointStart: Date.now(),  // Current time in milliseconds
        pointInterval: 5 * 1000, // 30 seconds
        type: 'candlestick',
        id: 'bitcoin-ohlc',
        name: `${coinName} Price`,
        turboThreshold: 5000,
        lastPrice: {
          enabled: true,
          label: {
            enabled: true,
            align: 'left',
            x: 8,
          },
        },
        data: [],
      },
      {
        pointStart: Date.now(),  // Current time in milliseconds
        pointInterval: 5 * 1000, // 30 seconds
        type: 'column',
        turboThreshold: 5000,
        lastPrice: {
          enabled: true,
          label: {
            enabled: true,
            align: 'left',
            x: 8,
          },
        },
        keys: ['x', 'y', 'className'],
        id: 'bitcoin-volume',
        name: `${coinName} Volume`,
        data: [],
        yAxis: 1,
      },
    ],
    tooltip: {
      split: true,
      shared: true,
    },
    plotOptions: {
      series: {
        animation: {
          duration: 800, // Smoother transitions
        },
        dataGrouping: {
          enabled: false, // Disable grouping for real-time updates
        },
      },
    },
    rangeSelector: {
      buttons: [{
        type: 'millisecond',
        count: 2 * 60 * 1000, // 2 minutes in milliseconds
        text: '2 m'
      }, {
        type: 'millisecond',
        count: 5 * 60 * 1000, // 5 minutes in milliseconds
        text: '5 m'
      }, {
        type: 'millisecond',
        count: 15 * 60 * 1000, // 15 minutes in milliseconds
        text: '15 m'
      }, {
        type: 'millisecond',
        count: 30 * 60 * 1000, // 30 minutes in milliseconds
        text: '30 m'
      }, {
        type: 'millisecond',
        count: 60 * 60 * 1000, // 1 hour in milliseconds
        text: '1 h'
      }, {
        type: 'millisecond',
        count: 3 * 60 * 60 * 1000, // 3 hours in milliseconds
        text: '3 h'
      }]
    },

  };


  useEffect(() => {

    setCurrentCoin(activeCoinId);
    if (activeCoinId == '6708ef77652a6be8b13b34a1') {
      setCoinName('Bitcoin');
    }

    if (activeCoinId == '6708ef8f652a6be8b13b364f') {
      setCoinName('Litecoin');
    }

    if (activeCoinId == '6708f00c652a6be8b13b3f57') {
      setCoinName('Tether');
    }

    if (activeCoinId == '6708f01e652a6be8b13b40c4') {
      setCoinName('Ethereum');
    }

    // Initialize the chart and store the reference
    chartRef.current = Highcharts.stockChart('container', chartOptions);
    const chart = chartRef.current;
    //const chart = Highcharts.stockChart(chartOptions); // Create the chart instance

    // Fetch Bitcoin data and predictions
    const fetchBitcoinValue = async (duration) => {
      try {
        const response = await axios.get(`${apiUrl}/price/bitcoin?duration=${duration}&coinId=${activeCoinId}`);
        const prices = response.data; // Access the actual data from the response

        const ohlc = [];
        const volume = [];
        const dataLength = prices.length;
        const splitIndex = Math.floor(dataLength * 0.7); // 60% actual, 40% predicted

        for (let i = 0; i < dataLength; i += 1) {
          const [timestamp, open, high, low, close, vol] = prices[i]; // Destructure each price entry

          // If all prices are the same, create variations
          const variationAmount = 10; // Maximum variation range

          // Create small variations to generate meaningful OHLC values
          const adjustedOpen = open; // Keep open price as is
          const adjustedClose = adjustedOpen + (Math.random() * variationAmount * (Math.random() < 0.5 ? -1 : 1)); // Randomly adjust close

          // Ensure high and low values are set based on adjustedOpen and adjustedClose
          const adjustedHigh = Math.max(adjustedOpen, adjustedClose) + (Math.random() * variationAmount); // High is above both open and close
          const adjustedLow = Math.min(adjustedOpen, adjustedClose) - (Math.random() * variationAmount); // Low is below both open and close

          const adjustedVolume = vol + Math.random() * 100; // Adjust volume slightly
          setBitcoinPrice(close);
          //setCurrentValue(adjustedClose);

          // Push the adjusted or original OHLC data to the ohlc array
          ohlc.push([timestamp, adjustedOpen, adjustedHigh, adjustedLow, adjustedClose]);


          // Keep the original or slightly modified volume data
          volume.push(
            [timestamp, vol + Math.random() * 100, adjustedOpen < adjustedClose ? 'highcharts-point-up' : 'highcharts-point-down']
          ); // Optional adjustment to volume

        }

        // Update the existing series with the fetched and predicted data
        if (chart) {
          const ohlcSeries = chart.get('bitcoin-ohlc');
          if (ohlcSeries) {
            ohlc.forEach((dataPoint) => {
              ohlcSeries.addPoint(dataPoint, false); // Add each data point individually
            });
          }

          const volumeSeries = chart.get('bitcoin-volume');
          if (volumeSeries) {
            volume.forEach((volumePoint) => {
              volumeSeries.addPoint(volumePoint, false); // Add each volume data point individually
            });
          }


          if (bets.length > 0) {
            bets.forEach((bet) => {
              const lineColor = bet.bet_type == 'high' ? '#00ff7e' : '#db4931';
              const className = bet.bet_type == 'high' ? 'highcharts-point-up' : 'highcharts-point-down';
              console.log('Plot Line Value:', bet.coin_price_at_bet);
              chart.current.yAxis[0].addPlotLine({
                color: bet.bet_type == 'high' ? '#00ff7e' : '#db4931', // Color based on direction
                value: bet.coin_price_at_bet,
                className:className,
                width: 2,
                dashStyle: 'dash',
                label: {
                  text: `Bet: $ ${bet.coin_price_at_bet}`, // Label for the threshold
                  align: 'right',
                  verticalAlign: 'bottom',
                  style: {
                    color: bet.bet_type == 'high'  ? '#00ff7e' : '#db4931',
                  },
                },
                zIndex: 7, // Ensure it's above other plot lines
              });
            });
          }

          chart.redraw(); // Redraw the chart with the new data
        }
      } catch (err) {
        setError('Error fetching Bitcoin data');
      }
    };

    getSingleUserData();

    // Fetch initially with a duration of 15 seconds
    fetchBitcoinValue(60);

    // Set interval to fetch data every 2 seconds
    const interval = setInterval(() => {
      fetchBitcoinValue(1); // Update data every 2 seconds
    }, 2000);

    return () => clearInterval(interval); // Clean up on component unmount
  }, [activeCoinId, bets]);

  const [amount, setAmount] = useState(1);
  const [expiration, setExpiration] = useState(1);

  // Common function to handle placing a bet
  const placeBet = async (betType) => {
    if (balance < amount) {
      return toast.error('Insufficient Balance! Please First Make Deposit');
    }

    console.log('bitcoinPrice => ', bitcoinPrice);

    const dataToSend = {
      coinId: currentCoin,
      userId: currentUser._id,
      betAmount: amount,
      expiration: expiration,
      bitcoinPrice: bitcoinPrice,
      betType, // Pass "high" or "low" as a parameter
    };

    try {
      const response = await axios.post(`${apiUrl}/bet/place-bet`, dataToSend);

      const direction = betType == "high" ? "up" : "down"; // Handle direction based on betType

      const newThreshold = { value: bitcoinPrice, direction };

      // Add plotLine directly to the chart
      if (chartRef.current) {
        const lineColor = newThreshold.direction === 'up' ? '#00ff7e' : '#db4931';
        const className = newThreshold.direction === 'up' ? 'highcharts-point-up' : 'highcharts-point-down';
        console.log('rrrr start', );
        chartRef.current.yAxis[0].addPlotLine({
          color: lineColor, 
          className:className,// Color based on direction
          value: newThreshold.value,
          width: 2,
          dashStyle: 'dash',
          label: {
            text: `Bet: $ ${newThreshold.value}`, // Label for the threshold
            align: 'right',
            verticalAlign: 'bottom',
            style: {
              color: lineColor,
            },
          },
          zIndex: 7, // Ensure it's above other plot lines
        });
        console.log('rrrr end', newThreshold.direction === 'up' ? '#00ff7e' : '#db4931');
      }

      const { current_balance } = response.data;
      dispatch(updateBalance(current_balance));

      toast.dismiss();
      toast.success('Bet Placed Successfully.');
    } catch (error) {
      console.log('place bet error ', error);
      toast.dismiss();
      toast.error('Failed to place bet.');
    }
  };

  // Specific functions for higher and lower clicks
  const onHigherClick = () => placeBet("high");
  const onLowerClick = () => placeBet("low");

  if (!currentUser) {
    return <Navigate to="/login" />;
  }


  return (
    <>
      <div className="container-fluid graph_ar px-0">
        <div className="row ">
          <div className="col-xxl-11 col-lg-10 col-sm-9 col-12 px-0 trading_graph_wrapper">
            <div className="tradinggraph" >

              <div id="container" className="chart">
                <HighchartsReact
                  highcharts={Highcharts}
                  constructorType={'stockChart'}
                  options={chartOptions}
                />
              </div>

            </div>
          </div>
          <div className="col-xxl-1 col-lg-2  col-sm-3  col-12 px-2 rightsidebar_wrapper">
            <div className="rightside_bar position-relative py-2">
              <div
                className={`popup br4 overflow-hidden  ${isActive ? "scale_in d-block" : "d-none"
                  }`}
              >
                <div className="popupheader p-2 px-3 text-white fw-bold">
                  Close Trade At Profit
                </div>
                <div className="popupbody p-3">
                  <Valuechanger lable={"Points"} className="" />
                  <Valuechanger lable={"Profit in Money"} className="" />
                  <div className="popupbtns d-flex align-items-center gap-2">
                    <button
                      className="btn w-50 btn-nm cancel_btn text_slate fs14 text-center br4 mt-2"
                      onClick={removeClass}
                    >
                      Cancel
                    </button>
                    <button className="btn w-50 btn-nm apply_btn text_slate fs14 text-center br4 mt-2">
                      Apply
                    </button>
                  </div>
                </div>
              </div>
              <div className="d-sm-block d-flex gap-1">
                <Valuechanger lable={"Amount"} onValueChange={(value) => setAmount(value)} />
                <Valuechanger lable={"Expiration"} onValueChange={(value) => setExpiration(value)} />
              </div>
              <div className="point_value mb-2 text-center">
                <div className=" d-flex align-items-center text-center gap-2 fs-6 py-2 text-white">
                  Profit{" "}
                  <FontAwesomeIcon icon={faCircleQuestion} className="" />
                </div>
                <div className="text_green fs-2 cprfont">+85%</div>
                <div className="text_green fw-semibold fs16 cprfont d-none">$4.10</div>
              </div>
              <div className="d-sm-block d-flex align-items-center gap-1 pnl_btns">
                <button className="btn_bulky btn bg_green text-white rounded mb-sm-2" onClick={onHigherClick}>
                  <FontAwesomeIcon icon={faChartLine} /><br />
                  Higher
                </button>

                <button className="btn_bulky btn bg_red text-white rounded mb-sm-2 " onClick={onLowerClick}>
                  <FontAwesomeIcon icon={faDownLong} /><br />
                  Lower
                </button>
              </div>
            </div>
          </div>
          <div className="col-12 px-0">
            <div class="accordion" id="margin_accord">
              <div class="accordion-item bgelem">
                <nav
                  className=""
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseOne"
                  aria-expanded="true"
                  aria-controls="collapseOne"
                >
                  <div class="nav nav-tabs " id="nav-tab" role="tablist">
                    <button
                      class="nav-link active"
                      id="nav-margin-tab"
                      data-bs-toggle="tab"
                      data-bs-target="#nav-margin"
                      type="button"
                      role="tab"
                      aria-controls="nav-margin"
                      aria-selected="true"
                    >Options
                    </button>
                  </div>
                </nav>
                <div
                  id="collapseOne"
                  class="accordion-collapse collapse show"
                  data-bs-parent="#margin_accord"
                >
                  <div class="accordion-body ">
                    <div class="tab-content" id="nav-tabContent">
                      <div
                        class="tab-pane fade show active"
                        id="nav-margin"
                        role="tabpanel"
                        aria-labelledby="nav-margin-tab"
                        tabindex="0"
                      >
                        <NonExpiredBetsTable />

                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default TradingGraph;
