import React, { useEffect, useState } from "react";
import { IoMdClose } from "react-icons/io";
import { GoDotFill } from "react-icons/go";
import { IoMdSend } from "react-icons/io";
import { VscAdd } from "react-icons/vsc";
import indianflag from "../../../images/iq/indian_flag.svg";
import { get_leader_of_the_week_data } from "../../../common/Api";

const Leaderboard = () => {
  const user = JSON.parse(localStorage.getItem("user"))
  const token = JSON.parse(localStorage.getItem("token"))
  const [leaders, setleaders] = useState([])
  const [self, setSelf] = useState({})

  const getLeaderOfTheWeek = async () => {
    try {
      const data = await get_leader_of_the_week_data(user._id, token)
      console.log(data)
      setleaders(data.data)
      setSelf(data.self)

    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    getLeaderOfTheWeek();
  }, [])

  console.log(leaders)
  return (
    <>
      <div class="offcanvas-header">
        <h6
          class="offcanvas-title text-white fw-normal"
          id="offcanvasExampleLabel"
        >
          Leaders of the Weak
        </h6>

        <IoMdClose
          className="fs-4 text-white ms-auto"
          data-bs-dismiss="offcanvas"
          aria-label="Close"
        />
      </div>
      <div class="offcanvas-body px-0 position-relative">
        <div className="px-3">


          <div className="ownstatus d-flex align-items-center justify-content-between gap-2 px-3 mb-3">
            <div className="place text_orng">-</div>
            <div className="name text-white d-flex align-items-center gap-1 fs12 ">
              <img src={indianflag} width="20px" alt="flagimg" /> {self?.user_id?.username}
            </div>
            <div className="pnl text_orng fs12 text-success fw-bold">$ {self?.potential_payout}</div>
          </div>
          <p className="text-white fs13 text_slate text-center px-3">
            Profitable trades in this week yet
          </p>
        </div>

        <div className="leaderboard_ranking px-3">
          <ul className="rankings px-0">
            {
              Array.isArray(leaders) && leaders.length > 0 ? (
                leaders.map((item) => {
                  return (
                    <li className="ranking_item list-unstyled" key={item.user_id?._id || item.user_id}>
                      <div className="ownstatus d-flex align-items-center justify-content-between gap-2 px-3 my-2">
                        <div className="place text_orng">{item.rank || leaders.indexOf(item) + 1}</div>
                        <div className="name text-white d-flex align-items-center gap-1 fs12">
                          {item?.user_id?.username || '-NA-'}
                        </div>
                        <div className="pnl text_orng fs12 text-success fw-bold">
                          $ {item?.potential_payout || '0.00'}
                        </div>
                      </div>
                    </li>
                  );
                })
              ) : (
                <li>No leaders available</li>
              )
            }


          </ul>
        </div>
      </div>
    </>
  );
};

export default Leaderboard;
