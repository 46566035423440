import React, { useEffect, useState } from 'react'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBagShopping, faCaretUp, faChartColumn, faClockRotateLeft, faEllipsis, faFire, faGraduationCap, faMedal,
  faMessage, faCaretDown
} from '@fortawesome/free-solid-svg-icons';
import { IoChevronDownOutline } from "react-icons/io5";
import { IoMdClose } from "react-icons/io";
import coinlogo from "../../../images/iq/litecoinlogo.png";
import { get_user_trading_history_data } from '../../../common/Api';
import moment from 'moment';

const TradingHistroy = ({ coins }) => {
  const [selectedCoin, setSelectedCoin] = useState('')
  const [data, setData] = useState([])
  const user = JSON.parse(localStorage.getItem("user"))
  const token = JSON.parse(localStorage.getItem("token"))

  const handleSelectedCoin = async (e) => {
    try {
      // alert(e.target.value)
      const datas = await get_user_trading_history_data(user._id, token, e.target.value)
      //console.log(data)
      setData(datas.data)
    } catch (error) {
      console.log(error)
    }

  }

  const onUseEff = async (req, res) => {
    try {
      // alert(e.target.value)
      const datas = await get_user_trading_history_data(user._id, token, 1)
      //console.log(data)
      setData(datas.data)
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    onUseEff();
  }, [user._id, token])


  return (

    <>
      <div class="offcanvas-header">
        <h6 class="offcanvas-title text-white" id="offcanvasExampleLabel">
          Trading History
        </h6>

        <IoMdClose className='fs-4 text-white ms-auto' data-bs-dismiss="offcanvas" aria-label="Close" />
      </div>
      <div class="offcanvas-body">

        <div class="dropdown bgelem  mt-2 mb-3 position_dropdown position-relative">
          <IoChevronDownOutline className='position-absolute end-0 me-2 translate-middle-y top-50 z-1 text-white' />
          <select className='form-control' onChange={handleSelectedCoin}>
            <option value="1" >All Coin</option>
            {
              coins?.map((items, i) => {
                return (
                  <option key={items?._id} value={items?._id}>{items?.name}</option>
                )
              })
            }


          </select>

        </div>

        <div className='tradehistroy_list'>
          <ul className='list-unstyled'>
            {
              data && data.length > 0 ? <>

                {
                  data?.map((items, i) => {
                    console.log('items =>', items);
                    return (
                      <li className='history_li_item d-flex gap-2 align-items-end text-center'>
                        <div className='time_n_date w-25'>
                          <div className='text_slate  fs12'>
                           <div className='fs13 mb-0 fw-normal text-white'> {moment(items?.created_at).format('hh:mm')}</div>
                            {moment(items?.created_at).format('DD MMM')}
                          </div>

                        </div>
                        <div className='w-50' >
                          <div className='d-flex align-items-center gap-2'>
                            <div className='curruncy_img'>
                              <img width="30px" src={coinlogo} alt="coinlogo" />
                            </div>
                            <div className='curruncy_name text-white fs14'>
                              Bitcoin
                            </div>
                            <div className='text_orng fw-semibold fs11 text-start text-break'>

                            </div>
                          </div>
                        </div>

                        <div className='w-25'>

                         {
                            items?.is_winner == true ? <div className='text_green fw-bold fs13'>
                             ${items?.potential_payout}
                            </div> : <div className='text_red fw-bold fs13'>
                            -${ items?.bet_amount}
                            </div>
                          }
                          
                          {
                            items?.bet_type == 'high' ? <div className='text_green fw-bold fs13'>
                              ${ items?.bet_amount}<FontAwesomeIcon className='text_green ms-2' icon={faCaretUp} />
                            </div> : <div className='text_red fw-bold fs13'>
                            ${ items?.bet_amount}<FontAwesomeIcon className='text_red ms-2' icon={faCaretDown} />
                            </div>
                          }
                        </div>
                      </li>

                    )
                  })
                }

              </> : <>
                <p style={{ color: "#fff" }}>No record.</p>
              </>

            }


          </ul>
        </div>
      </div>
    </>
  )
}

export default TradingHistroy