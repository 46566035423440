import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import axios from 'axios';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faCaretUp, faCaretDown
} from '@fortawesome/free-solid-svg-icons';
import toast, { Toaster } from 'react-hot-toast';

const NonExpiredBetsTable = () => {
    const { user: currentUser } = useSelector((state) => state.auth);
    const [bets, setBets] = useState([]);
    const [currentPrice, setCurrentPrice] = useState(null);
    const [expiredBets, setExpiredBets] = useState([]);
    const apiUrl = process.env.REACT_APP_API_URL;

    useEffect(() => {

        const fetchNonExpiredBets = async () => {
            try {
                const response = await axios.get(`${apiUrl}/price/fetch_non_expired_bets`, {
                    params: { userId: currentUser._id }
                });

                if (response.data.status) {
                    const newBets = response.data.data.allBets;

                    // Get current date and time
                    const now = new Date();

                    // Filter out non-expired bets and expired bets
                    const nonExpiredBets = newBets.filter(bet => new Date(bet.expiration_time) > now);
                    const expiredBetsList = newBets.filter(bet => new Date(bet.expiration_time) <= now);

                    // Create a set of existing bet IDs for quick lookup
                    const existingBetIds = new Set(bets.map(bet => bet._id));

                    // Filter out any new bets that are already in the existing bets
                    const uniqueNonExpiredBets = nonExpiredBets.filter(bet => !existingBetIds.has(bet._id));

                    // Update the bets state with unique non-expired bets
                    setBets(prevBets => [...uniqueNonExpiredBets]);

                    // Update expired bets (avoid duplicates)
                    setExpiredBets(prevExpired => {
                        const newExpiredBets = expiredBetsList.filter(expiredBet => !prevExpired.some(prev => prev._id === expiredBet._id));
                        return [...prevExpired, ...newExpiredBets];
                    });

                    // Update current price
                    setCurrentPrice(response.data.data.currentPrice);
                }
            } catch (error) {
                console.error('Error fetching bets:', error);
            }
        };

        fetchNonExpiredBets();

        // Refresh price and fetch bets every 10 seconds
        const interval = setInterval(fetchNonExpiredBets, 10000); // Set to 10 seconds for demo purposes

        return () => clearInterval(interval); // Clear interval on component unmount
    }, [currentUser._id]);


    // Function to calculate the time left until expiration
    const calculateTimeLeft = (expirationTime) => {
        const now = new Date();
        const expirationDate = new Date(expirationTime);
        const difference = expirationDate - now;

        let timeLeft = {};
        if (difference > 0) {
            timeLeft = {
                hours: Math.floor(difference / (1000 * 60 * 60)),
                minutes: Math.floor((difference / (1000 * 60)) % 60),
                seconds: Math.floor((difference / 1000) % 60),
            };
        } else {
            timeLeft = {
                hours: 0,
                minutes: 0,
                seconds: 0,
            };
        }

        return timeLeft;
    };

    return (
        <div>
            {expiredBets.length > 0 && (
                <div className="alert bg_red alert-dismissible fade show" role="alert">
                    {expiredBets.length} bet(s) have completed.
                    <button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
                </div>
            )}
            <table className="table">
                <thead className="text-white">
                    <tr>
                        <th scope="col" className="text-white fw-normal fs14">Name</th>
                        <th scope="col" className="text-white fw-normal fs14">Time Left</th>
                        <th scope="col" className="text-white fw-normal fs14">Investment</th>
                        <th scope="col" className="text-white fw-normal fs14">Potential Payout</th>
                        <th scope="col" className="text-white fw-normal fs14">Current Price</th>
                    </tr>
                </thead>
                <tbody className="fw-normal fs14">
                    {bets.length > 0 ? (
                        bets.map((bet) => {
                            const timeLeft = calculateTimeLeft(bet.expiration_time);

                            return (
                                <tr key={bet._id}>
                                    <td>
                                        <div className='text-white'>
                                            Bitcoin
                                        </div>
                                    </td>
                                    <td>
                                        <div className='text_orng'>
                                            {timeLeft.hours}h {timeLeft.minutes}m {timeLeft.seconds}s
                                        </div>
                                    </td>
                                    <td>
                                        <div className='text-white'>
                                            {
                                                bet?.bet_type === 'high' ?
                                                    <div className='text_green fw-bold fs13'>
                                                        ${bet?.bet_amount}<FontAwesomeIcon className='text_green ms-2' icon={faCaretUp} />
                                                    </div> :
                                                    <div className='text_red fw-bold fs13'>
                                                        ${bet?.bet_amount}<FontAwesomeIcon className='text_red ms-2' icon={faCaretDown} />
                                                    </div>
                                            }
                                        </div>
                                    </td>
                                    <td>
                                        <div className='text-white'>
                                            ${bet.potential_payout}
                                        </div>
                                    </td>
                                    <td>
                                        <div className='text_orng'>
                                            {currentPrice ? currentPrice.toFixed(2) : 'Fetching...'}
                                        </div>
                                    </td>
                                </tr>
                            );
                        })
                    ) : (
                        <tr>
                            <td colSpan="6">No bets found.</td>
                        </tr>
                    )}
                </tbody>
            </table>
        </div>
    );
};

export default NonExpiredBetsTable;
