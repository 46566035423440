import React, { useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { updateBalance, updateActiveCoinId } from "../../../slices/auth";
import { Link, Navigate, useNavigate } from "react-router-dom";
import Logo from "../../../images/iq/beeta2.png";
import coinlogo from "../../../images/iq/money.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark } from "@fortawesome/free-solid-svg-icons";
import countryflag from "../../../images/iq/indian_flag.svg";
import Button from "react-bootstrap/Button";
import ButtonGroup from "react-bootstrap/ButtonGroup";
import { IoMdAdd } from "react-icons/io";
import { logout } from "../../../slices/auth";
import { GiHamburgerMenu } from "react-icons/gi";
import axios from "axios"; // Assuming you're using axios for API requests

import { currencyFormatter } from '../../../common/currencyFormatter';

import {
  faCircleUser,
  faChevronDown,
  faCircleExclamation,
  faCopy,
  faCamera,
  faCirclePlus,
  faCircleArrowRight,
  faCircleQuestion,
  faCoins,
  faClockRotateLeft,
  faGear,
  faRightFromBracket,
  faUserTie,
} from "@fortawesome/free-solid-svg-icons";
import { MyContext } from "../Context";
import {
  add_balance_in_the_user_data,
  get_user_single_data,
} from "../../../common/Api";
import toast from "react-hot-toast";
import SidebarMobile from "../Offcanvas/SidebarMobile";



const Header = () => {
  const { balanceAmount } = useSelector((state) => state.auth);
  const [coins, setCoins] = useState([]);
  const [activeCoin, setActiveCoin] = useState('6708ef77652a6be8b13b34a1');
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const upload = process.env.REACT_APP_UPLOAD;

  const handleLogout = () => {
    localStorage.removeItem("user");
    localStorage.removeItem("token");
    dispatch(logout());

    // Redirect to the login page
    navigate("/login");
  };
  const user = JSON.parse(localStorage.getItem("user"));
  const token = JSON.parse(localStorage.getItem("token"));
  const [users, setUsers] = useState({});
  const [amount, setAmount] = useState(9999);

  const [session, setSession] = useState(users?.activeSession);
  const apiUrl = process.env.REACT_APP_API_URL;
  const updateSession = async (newSession) => {
    try {
      const response = await axios.put(`${apiUrl}/user/updateActiveSession/${users._id}`, {
        activeSession: newSession,
      });
      if (response.status === 200) {
        setSession(newSession); // Update the session state

        user_data();


        navigate("/login");

        console.log("Session updated successfully:", response.data);
      }
    } catch (error) {
      console.error("Failed to update session:", error);
    }
  };
  const fetchCoins = async () => {
    try {
      const response = await axios.get(`${apiUrl}/price/get_coins`);
      if (response.data.status) {
        setCoins(response.data.data);
      } else {
        setError('Failed to fetch coins.');
      }
    } catch (error) {
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };




  const user_data = async () => {
    try {
      const data = await get_user_single_data(user._id, token);
      console.log(data);
      dispatch(updateBalance(data?.data?.activeSession === 0 ? data?.data?.pacticeBalance : data?.data?.balance));

      setUsers(data.data);
    } catch (error) {
      console.log(error);
    }
  };


  console.log('activeCoin => ', activeCoin);

  const [isActive, setisActive] = useState(false);
  const { value, updateValue } = useContext(MyContext);
  const [active, toggleClass] = useState(MyContext);
  const [Showelem, setShowElem] = useState(false);

  const handleShowelem = () => {
    setShowElem((prev) => !prev);
  }

  const handleChange = () => {
    updateValue((prev) => !prev); // Toggle the active state
  };

  const handleShow = () => {
    toggleClass(); // This will now toggle the active state
    alert("clicked");
  };

  const addClass = () => {
    setisActive(true);
  };
  const removeClass = () => {
    setisActive(false);
  };

  const [activeCoinIndex, setActiveCoinIndex] = useState(3);


  const handleCoinClick = (index) => {
    setActiveCoinIndex(index); // Set the active coin index
    setActiveCoin(coins[activeCoinIndex]?._id);

    dispatch(updateActiveCoinId(coins[activeCoinIndex]?._id));
    navigate("/login");
    setShowElem(false);
  };

  const hanleTopUpToMoveDeposite = async (req, res) => {
    setisActive(false);
    navigate("/DepositHistory");
  };

  const handlePractiseAmount = async () => {
    const sumbitData = {
      amount: amount,
    };
    try {
      const data = await add_balance_in_the_user_data(
        user._id,
        token,
        sumbitData
      );
      if (data.status == true) {
        toast.success(data.message);
        user_data();
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      await user_data();
      await fetchCoins();
      setActiveCoin(coins[activeCoinIndex]?._id);
    };

    fetchData();
  }, [activeCoinIndex, activeCoin]);


  return (
    <>
      <header className="py-sm-3 py-1 header_Trading_Room">
        <div className="container-fluid ">
          <div className="row align-items-start gap-3 px-3">
            <div className="d-flex flex-sm-row flex-column align-items-sm-center w-auto gap-3 px-0">
              <Link class="navbar-brand w-auto" to="/dashboard">
                <img src={Logo} alt="Logo" width={"125px"} />
              </Link>

              <div className="d-flex flex-row gap-3 align-items-center mb-sm-0 mb-2">

                <div
                  key={activeCoinIndex + 1}
                  className={`coin d-flex align-items-center gap-1 ps-3 pe-5 py-1 w-auto  active`}
                  style={{
                    borderBottom: "2px solid var(--orng)",
                    cursor: "pointer",
                  }}
                  onClick={() => handleCoinClick(activeCoinIndex)}
                >
                  <div className="coinlogo">
                    <img
                      src={coinlogo}
                      alt={`${coins[activeCoinIndex]?.name} logo`}
                      width={"28px"}
                    />
                  </div>
                  <div className="coin_dets text-white">
                    <div
                      className="coinname"
                      style={{ fontSize: "12px", fontWeight: "600" }}
                    >
                      {coins[activeCoinIndex]?.name}
                    </div>
                    <div
                      className="coinprice d-none"
                      style={{ fontSize: "12px", fontWeight: "600" }}
                    >
                      {coins[activeCoinIndex]?.price}
                    </div>
                  </div>
                </div>


                <div className="addcoin_dd position-relative " >
                  <IoMdAdd class="text_slate fs-2" onClick={handleShowelem} />
                  <div className={`coinslist_wrapper bgelem p-4  ${Showelem ? "scale_in d-block" : "d-none"}`} >
                    <h5 className="text-white mb-3">Pick Your Choice</h5>
                    <div className="row coinscontainer">
                      {coins.map((coin, index) => (

                        <div className="col-6 mb-4">
                          <div
                            className={`coin d-flex align-items-center gap-1 ps-3 pe-5 py-1 w-auto active `}
                            style={{
                              borderBottom: "2px solid var(--orng)",
                              cursor: "pointer",
                            }}
                            onClick={() => handleCoinClick(index)}
                          >
                            <div className="coinlogo">
                              <img src={coinlogo} width={"28px"} />
                            </div>
                            <div className="coin_dets text-white">
                              <div
                                className="coinname"
                                style={{ fontSize: "12px", fontWeight: "600" }}
                              >
                                {coin.name}
                              </div>
                              <div
                                className="coinprice d-none"
                                style={{ fontSize: "12px", fontWeight: "600" }}
                              >
                                $999999
                              </div>
                            </div>
                          </div>
                        </div>
                      ))}

                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="header_right w-auto ms-auto d-flex flex-row align-items-center gap-sm-3 gap-2 mt-sm-0 mt-2 px-0">


              <div class="btn-group">
                <button
                  class="btn p-0 "
                  type="button"
                  data-bs-toggle="dropdown"
                  data-bs-auto-close="outside"
                  aria-expanded="false"
                >
                  <div
                    className="usericon d-flex align-items-center gap-1 position-relative"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseExample"
                    aria-expanded="false"
                    aria-controls="collapseExample"
                  >
                    {
                      users?.profileImg ? <img className="ppI" src={`${upload}/${users?.profileImg}`} /> : <FontAwesomeIcon
                        icon={faCircleUser}
                        className="fs-2 text-white"
                      />
                    }

                  </div>
                </button>
                <ul
                  class="dropdown-menu usermenu p-0 curved"
                  style={{ width: "250px", left: "-275px" }}
                >
                  <div className="row">
                    <div
                      className="col-12"
                      style={{ backgroundColor: "rgb(28 32 48)" }}
                    >
                      <div className="usermenu  py-3">
                        <ul className="list-unstyled">
                          {[
                            {
                              icon: (
                                <FontAwesomeIcon
                                  icon={faCirclePlus}
                                  className="fs-6"
                                />
                              ),
                              text: "Personal Data",
                              url: "/profile/personal",
                            },
                            {
                              icon: (
                                <FontAwesomeIcon
                                  icon={faUserTie}
                                  className="fs-6"
                                />
                              ),
                              text: "Deposit Funds",
                              url: "",
                              url: "/DepositHistory",
                            },
                            {
                              icon: (
                                <FontAwesomeIcon
                                  icon={faCircleArrowRight}
                                  className="fs-6"
                                />
                              ),
                              text: "Withdraw Funds",
                              url: "/withdrawl",
                            },
                            {
                              icon: (
                                <FontAwesomeIcon
                                  icon={faCircleQuestion}
                                  className="fs-6"
                                />
                              ),
                              text: "Contact Support",
                              url: "/contact-support",
                            },
                            {
                              icon: (
                                <FontAwesomeIcon
                                  icon={faCoins}
                                  className="fs-6"
                                />
                              ),
                              text: "Balance History",
                              url: "/transactions",
                            },
                            {
                              icon: (
                                <FontAwesomeIcon
                                  icon={faClockRotateLeft}
                                  className="fs-6"
                                />
                              ),
                              text: "Trading History",
                              url: "/tradinghistory",
                            },
                            {
                              icon: (
                                <FontAwesomeIcon
                                  icon={faGear}
                                  className="fs-6"
                                />
                              ),
                              text: "Settings",
                              url: "/Settings",
                            },
                            {
                              icon: (
                                <FontAwesomeIcon
                                  icon={faRightFromBracket}
                                  className="fs-6"
                                />
                              ),
                              text: "Logout",
                              onClick: handleLogout, // Attach the logout handler here
                            },
                          ].map((listitem, index) => (
                            <li
                              key={index}
                              className="text-white fs14 px-3 curved"
                              style={{ paddingBlock: "6px" }}
                            >
                              {listitem.onClick ? (
                                <button
                                  className="text-white text-decoration-none d-flex align-items-center gap-2"
                                  style={{
                                    background: "none",
                                    border: "none",
                                    padding: 0,
                                  }}
                                  onClick={listitem.onClick}
                                >
                                  <span className="text_slate listitemicon">
                                    {listitem.icon}
                                  </span>
                                  {listitem.text}
                                </button>
                              ) : (
                                <Link
                                  to={listitem.url}
                                  className="text-white text-decoration-none d-flex align-items-center gap-2"
                                >
                                  <span className="text_slate listitemicon">
                                    {listitem.icon}
                                  </span>
                                  {listitem.text}
                                </Link>
                              )}
                            </li>
                          ))}
                        </ul>
                      </div>
                    </div>
                  </div>
                </ul>
                {/* <GiHamburgerMenu className="text_slate fs-4" /> */}
              </div>
              <div></div>


              <div class="btn-group" onClick={addClass} onBlur={removeClass}>
                <button
                  class="btn px-0 "
                  type="button"
                  data-bs-toggle="dropdown"
                  data-bs-auto-close="outside"
                  aria-expanded="false"
                >
                  <div
                    className=" d-flex align-items-center gap-1 position-relative"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseExample"
                    aria-expanded="false"
                    aria-controls="collapseExample"
                  >
                    <span
                      className="fs-5 fw-bold"
                      style={{ color: "var(--orng)" }}
                    >
                      {currencyFormatter((!balanceAmount ?
                        (users?.activeSession === 0 ? users?.pacticeBalance : users?.balance)
                        : balanceAmount), 'en-US', 'USD')}



                      <FontAwesomeIcon
                        icon={faChevronDown}
                        className="ms-1 fs-6"
                      />
                    </span>
                  </div>
                </button>
                <ul
                  class="dropdown-menu usermenu p-0 curved "
                  style={{ width: "320px", left: "-275px" }}
                >
                  <div className="row">

                    <div
                      className="col-12 "
                      style={{
                        backgroundColor: "rgb(38 44 59 / 99%)",
                        padding: "0 0 0 2px", cursor: "pointer"
                      }}
                    >
                      <div className="overflow-hidden">
                        <div className="flip-main-container">
                          <div className={isActive ? "flip-container" : null} onClick={() => updateSession(1)}>
                            <div
                              className="flipper p-3  d-flex align-items-center justify-content-between"
                              style={users?.activeSession === 1 ? { backgroundColor: "#363c4f" } : {}}
                            >
                              <div className="acc-type">
                                <div className="text-uppercase fs12 text-white fw-bold">
                                  Real Account
                                </div>
                                <div className="text_green fs14 fw-bold">
                                  {currencyFormatter(users?.balance, 'en-US', 'USD')}
                                </div>
                              </div>
                              <ButtonGroup aria-label="" style={{ gap: "1px" }}>

                                <Button
                                  className="btngrey withdrawbtn border-0 border-0 curved py-0 "
                                  style={{ marginRight: "1px" }}

                                >
                                  <Link to="/withdrawl" onClick={(e) => {
                                    e.stopPropagation(); // Prevent event from propagating to parent div
                                  }}>
                                    <svg
                                      fill="rgb(123 127 136)"
                                      style={{ transform: "scaleX(-1)" }}
                                      id="Layer_1"
                                      enable-background="new 0 0 512 512"
                                      height="18px"
                                      viewBox="0 0 512 512"
                                      width="18px"
                                      xmlns="http://www.w3.org/2000/svg"
                                    >
                                      <path d="m496.987 479.867c-7.263 0-13.569-5.252-14.783-12.535-10.824-63.203-72.026-173.512-227.271-179.958v81.093c0 5.619-3.14 10.767-8.136 13.338-4.997 2.57-11.011 2.133-15.583-1.132l-224.933-160.667c-3.942-2.816-6.281-7.363-6.281-12.207 0-4.845 2.339-9.391 6.281-12.206l224.934-160.666c4.571-3.266 10.586-3.703 15.582-1.132s8.136 7.719 8.136 13.338v82.315c95.963 9.572 257.067 78.84 257.067 335.418 0 7.805-5.985 14.306-13.763 14.949-.419.034-.836.052-1.25.052z" />
                                    </svg>
                                  </Link>
                                </Button>
                                <Link
                                  onClick={(e) => {
                                    e.stopPropagation(); // Prevent event from propagating to parent div
                                  }}
                                  className={`fs14 ${users?.activeSession === 1 ? "bggrey text-white " : "btngrey text-white"} border-0 curved px-4 py-1 btngrey text-decoration-none`}
                                  to="/DepositHistory"
                                >
                                  Deposit
                                </Link>
                              </ButtonGroup>
                            </div>
                          </div>
                          <div className={isActive ? "flip-container" : null} onClick={() => updateSession(0)} >
                            <div
                              className="flipper p-3 d-flex align-items-center justify-content-between"
                              style={users?.activeSession === 0 ? { backgroundColor: "#363c4f" } : {}}
                            >
                              <div className="acc-type">
                                <div className="text-uppercase fs12 text-white fw-bold">
                                  Practice Account
                                </div>
                                <div className="text_orng fs14 fw-bold">
                                  {currencyFormatter(users?.pacticeBalance, 'en-US', 'USD')}
                                </div>
                              </div>
                              <Button
                                className="fs14 border-0 curved px-4 btngrey"
                                onClick={handlePractiseAmount}
                              >
                                Top Up
                              </Button>{" "}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </ul>
              </div>

              <button
                type="button"
                class="btn fs-5 btn_big btn_green outline_green d-sm-block d-none"
                data-bs-toggle="modal"
                data-bs-target="#staticBackdrop"
              >
                Deposit
              </button>

              <div
                class="modal fade"
                id="staticBackdrop"
                data-bs-backdrop="static"
                data-bs-keyboard="false"
                tabindex="-1"
                aria-labelledby="staticBackdropLabel"
                aria-hidden="true"
              >
                <div
                  class="modal-dialog modal-dialog-centered"
                  style={{ maxWidth: "750px" }}
                >
                  <div
                    class="modal-content"
                    style={{ backgroundColor: "rgba(38, 44, 59, 0.99)" }}
                  >
                    <div
                      class="modal-header text-white border-0 align-items-center justify-content-between"
                      style={{ backgroundColor: "rgb(50 57 73)" }}
                    >
                      <h1
                        class="modal-title fs-5 text-white"
                        id="staticBackdropLabel"
                      >
                        Make a Deposit
                      </h1>

                      <button
                        class="btn btnclose text_grey"
                        data-bs-dismiss="modal"
                        aria-label="Close"
                      >
                        <FontAwesomeIcon icon={faXmark} />
                      </button>
                    </div>
                    <div class="modal-body px-4 py-5">
                      <div className="row">
                        <div className="col-6">
                          <div className="text-white text-center">
                            <p className="mb-0">Practice Account</p>
                            <p className="text_orng fs-3 fw-bold mb-3">
                              {currencyFormatter(users?.pacticeBalance, 'en-US', 'USD')}
                            </p>
                            <ul className="benefit_list list-unstyled fs14 mb-3">
                              <li className="text_orng ">No withdrawls</li>
                              <li className=" ">All assets available</li>
                              <li className=" ">Full fledged platform</li>
                            </ul>
                            <div>
                              <Button
                                className="w-100 bgelem border-0 fillup_btn"
                                size="lg"
                                onClick={handlePractiseAmount}
                              >
                                <span className="fs-5 text_slate fw-bold">
                                  Fill Up To {currencyFormatter(10000, 'en-US', 'USD')}
                                </span>
                                <div className="fs12 text_slate">
                                  Free top-up
                                </div>
                              </Button>
                            </div>
                          </div>
                        </div>
                        <div className="col-6">
                          <div className="text-white text-center">
                            <p className="mb-0">Real Account</p>
                            <p className="text_green fs-3 fw-bold mb-3">
                              {currencyFormatter(users?.balance, 'en-US', 'USD')}
                            </p>
                            <ul className="benefit_list list-unstyled fs14 mb-3">
                              <li className="text_green ">Easy withdrawls</li>
                              <li className=" ">All assets available</li>
                              <li className=" ">Full fledged platform</li>
                            </ul>
                            <div>
                              <Button className="w-100 bg_green border-0" size="lg" data-bs-dismiss="modal" onClick={hanleTopUpToMoveDeposite}>
                                <Link className="w-100  border-0 text-white text-decoration-none" size="lg" to="/DepositHistory">
                                  <span className="fs-5">
                                    Top Up Your Account
                                  </span>
                                  <div className="fs12 opacity-75">
                                    And start real trading
                                  </div>
                                </Link>
                              </Button>
                            </div>
                          </div>
                        </div>
                        <div className="text-center  fs12 mt-3 text_slate fw-normal">
                          You can switch between your accounts at any moment
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <GiHamburgerMenu className="text-white fs32 d-none" data-bs-toggle="offcanvas" data-bs-target="#mobiletoggler" aria-controls="mobiletoggler" />
              <div className="">

                {/* <SidebarMobile  /> */}
              </div>
            </div>
          </div>
        </div>
      </header>
    </>
  );
};

export default Header;
