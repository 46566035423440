import React, { useEffect, useState } from 'react'
import { Col, Container, FormSelect, Row } from "react-bootstrap";
import { get_user_trading_history_data, get_user_coin_data } from '../../common/Api';
import moment from 'moment';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBagShopping, faCaretUp, faChartColumn, faClockRotateLeft, faEllipsis, faFire, faGraduationCap, faMedal,
  faMessage, faCaretDown
} from '@fortawesome/free-solid-svg-icons';
import { IoChevronDownOutline } from "react-icons/io5";
import coinlogo from "../../images/iq/litecoinlogo.png";

const TradingHistory = () => {
  const [coin, setCoin] = useState([])
  const [selectedCoin, setSelectedCoin] = useState('')
  const [data, setData] = useState([])
  const user = JSON.parse(localStorage.getItem("user"))
  const token = JSON.parse(localStorage.getItem("token"))

  const getCoin = async () => {
    try {
      const data = await get_user_coin_data(user._id, token)
      console.log(data)
      setCoin(data.coin)

    } catch (error) {
      console.log(error)
    }
  }

  const handleSelectedCoin = async (e) => {
    try {
      // alert(e.target.value)
      const datas = await get_user_trading_history_data(user._id, token, e.target.value)
      //console.log(data)
      setData(datas.data)
    } catch (error) {
      console.log(error)
    }

  }

  const onUseEff = async (req, res) => {
    try {
      // alert(e.target.value)
      const datas = await get_user_trading_history_data(user._id, token, 1)
      //console.log(data)
      setData(datas.data)
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    onUseEff();
    getCoin()
  }, [user._id, token])


  return (
    <>
      <Container>

        <Row className="py-5">
          <Col lg={11} className="mx-auto">
            <div className='mb-5'>
              <h2 className='text-center'>Trading History</h2>
            </div>
            <Row>
              <Col md={6}>
                <label className='text_slate mb-1 fs14'>Coin</label>
                <select className='form-control' onChange={handleSelectedCoin}>
                  <option value="1" >All Coin</option>
                  {
                    coin?.map((items, i) => {
                      return (
                        <option key={items?._id} value={items?._id}>{items?.name}</option>
                      )
                    })
                  }


                </select>
              </Col>
              <Col md={6}>
                {/* <label className='text_slate mb-1 fs14'>Date</label>
                <FormSelect size="lg" className='fs16'>
                  <option>Large select</option>
                </FormSelect> */}
              </Col>
              <Col>
              
              </Col>

              <Col xs={12} className='my-4 history_result_wrapper d-flex align-items-center justify-content-center'>
                <div className='history_result w-100'>
                  <ul className='list-unstyled'>
                    {
                      data && data.length > 0 ? <>

                        {
                          data?.map((items, i) => {
                            console.log('items =>', items);
                            return (
                              <li className='history_li_item d-flex gap-2 align-items-end text-center'>
                                <div className='time_n_date w-25 text-start'>
                                  <div className='text_slate  fs12'>
                                    <div className='fs13 mb-0 fw-normal text_orng'>

                                      {moment(items?.created_at).format('hh:mm DD MMM')}
                                    </div>
                                  </div>

                                </div>
                                <div className='w-50' >
                                  <div className='d-flex align-items-center gap-2'>
                                    <div className='curruncy_img'>
                                      <img width="30px" src={coinlogo} alt="coinlogo" />
                                    </div>
                                    <div className='curruncy_name text-dark fw-semibold fs14'>
                                      Bitcoin
                                    </div>
                                    <div className='text_orng fw-semibold fs11 text-start text-break'>

                                    </div>
                                  </div>
                                </div>

                                <div className='w-25 text-end'>

                                  {
                                    items?.is_winner == true ? <div className='text_green fw-bold fs13'>
                                      ${items?.potential_payout}
                                    </div> : <div className='text_red fw-bold fs13'>
                                      -${items?.bet_amount}
                                    </div>
                                  }

                                  {
                                    items?.bet_type == 'high' ? <div className='text_green fw-bold fs13'>
                                      ${items?.bet_amount}<FontAwesomeIcon className='text_green ms-2' icon={faCaretUp} />
                                    </div> : <div className='text_red fw-bold fs13'>
                                      ${items?.bet_amount}<FontAwesomeIcon className='text_red ms-2' icon={faCaretDown} />
                                    </div>
                                  }
                                </div>
                              </li>

                            )
                          })
                        }

                      </> : <>
                        <div className='empty text-center fw-semibold'>
                          No Data Found
                          <div>
                            <span dir="auto" className='text_slate'>You may want to select different parameters or change the time period.</span>
                          </div>
                        </div>
                      </>

                    }


                  </ul>


                </div>
              </Col>
            </Row>

            <Row >

            </Row>

          </Col>

        </Row>

      </Container>

    </>
  )
}

export default TradingHistory